import React, { useState, useEffect } from 'react';
import _path from 'ramda/src/path';
import Moment from 'moment';
import Spinner from '../Common/Spinner';
import Homepage from '../../API/Homepage';
import GeneralUtility from '../../utilities/generalUtility';

const NewsReleasesContainer = () => {
	const [newsReleases, setNewsReleases] = useState([]);
	const [preloading, setPreLoading] = useState(true);
	const [notfound, setNotfound] = useState(false);
	let uniqueKey = 0;
	const newsNumbers = 9;

	useEffect(() => {
		const pendingRequests = [];
		const homepageFeed = Homepage.read({ count: newsNumbers });
		GeneralUtility.appendPendingRequest(pendingRequests, homepageFeed);
		homepageFeed.request
			.then(response => {
				if (!_path(['data', 'Success'], response)) {
					throw _path(['data', 'Message'], response);
				}
				setNewsReleases(response.data.Data);
                setPreLoading(false);
			})
			.catch(error => {
                GeneralUtility.logWebRequestFailure(error, `fetch news releases on homepage page`, true);
				setNotfound(true);
				setPreLoading(false);
			});
		return () => {
			GeneralUtility.cleanupRequests(pendingRequests);
		};
	}, []);

    const handleClickOnNews = link => {
		window.open(link, '_blank');
	};

	return (
		<React.Fragment>
			<Spinner size={'re-icon-xxl'} color={'re-icon-grey-light'} loading={preloading} />
			{notfound ? (
				<div className="re-notfound-container">
					<p>Read the latest news releases from RESAAS</p>{' '}
					<a data-testid="newsnotfound" href="https://corporate.resaas.com/news/" className="re-btn-notfound">
						Read More
					</a>
				</div>
			) : null}
			{newsReleases.length > 0 &&
				newsReleases.map(news => {
					uniqueKey = uniqueKey + 1;
					return (
						<div className="re-homepage-section-9-item" key={uniqueKey}>
                            <div data-testid={`news${uniqueKey}`} onClick={() => handleClickOnNews(news.Link)}>
								<div className="re-homepage-section-9-item-img">
									<img
										src={`${news.Image}?width=400&format=jpg&format=30`}
										srcSet={`${news.Image}?width=400&format=jpg&format=30 1x, ${news.Image}?width=600&format=jpg&format=30 1.5x, ${news.Image}?width=800&format=jpg&format=30 2x`}
									/>
                                </div>
                                <h2 className="re-text-sm re-text-bold re-homepage-news-header">{news.Title}</h2>
								<span className="re-homepage-section-9-date">{Moment(news.PubDate).format('LL')}</span>
								<div className="re-homepage-section-9-item-desc">
									<p dangerouslySetInnerHTML={{ __html: news.Description }}></p>
								</div>
							</div>
							<div className="re-homepage-section-9-item-more">
								<hr className="re-homepage-section-9-item-hr" />
								<a href={news.Link} target="_blank">
									READ MORE
									<span className="re-icon re-icon-angle-right"></span>
								</a>
							</div>
						</div>
					);
				})}
		</React.Fragment>
	);
};

export default NewsReleasesContainer;
