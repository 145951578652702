import React, { useState, useEffect, useRef } from 'react';
import Image from '../Common/Image';

const AwardsContainer = props => {
    const getSlideNumbers = () => {
        if (window.innerWidth <= 416) {
            return 1;
        } else if (window.innerWidth > 416 && window.innerWidth < 960) {
            return 2;
        } else {
            return 3;
        }
    };
    const awards = [
        {
            id: 1,
            image: 'https://az291210.vo.msecnd.net/assets/32d534c2-3ab9-4c61-b7d6-b59dca012ba5.png',
            alt: 'PROPTECH 2022',
            width: 180
        },
        {
            id: 2,
            image: 'https://az291210.vo.msecnd.net/assets/c85dd62d-fe94-4e95-9556-fe1d335dd387.png',
            alt: 'GetApp 2023',
            width: 200
        },
        {
            id: 3,
            image: 'https://az291210.vo.msecnd.net/assets/8cbcd50b-1bcf-400a-b15c-c439674ad96f.png',
            alt: 'Tech100',
            width: 250
        },
        {
            id: 4,
            image: 'https://az291210.vo.msecnd.net/assets/3d7a59c4-0e0f-43f9-817b-99891e2132e3.png',
            alt: 'PROPTECH',
            width: 200
        },
        {
            id: 5,
            image: 'https://az291210.vo.msecnd.net/assets/963e372d-a106-4684-9f7b-18744b547fd5.png',
            alt: 'Microsoft Canada',
            width: 250
        },
        {
            id: 6,
            image: 'https://az291210.vo.msecnd.net/assets/e0a4468b-f5b5-427a-9c44-a6575f6f4e7c.png',
            alt: 'RETech',
            width: 250
        },
        {
            id: 7,
            image: 'https://az291210.vo.msecnd.net/assets/26a9f091-cb0f-42c2-814e-befab41ac718.png',
            alt: 'T3',
            width: 150
        },
        {
            id: 8,
            image: 'https://az291210.vo.msecnd.net/assets/e7558152-3a86-4d8a-ae1d-69c8c4e81656.png',
            alt: 'Imman Innovator',
            width: 250
        },
        {
            id: 9,
            image: 'https://az291210.vo.msecnd.net/assets/f883220d-e7ab-41e9-a2ca-0ae92a5ff0dc.png',
            alt: 'GetApp 2022',
            width: 200
        },
    ];

    const slideNumbers = getSlideNumbers();

    const [currentSlideNumber, setCurrentSlideNumber] = useState(0);
    const [slidesList, setSlidesList] = useState(new Array(Math.ceil(awards.length / slideNumbers)));

    const delay = 8000;
    const timeoutRef = useRef(null);

    const handleSlidesList = slideNumbers => {
        let index = 0;
        let newState = [];

        for (let i = 0; i < awards.length; i += slideNumbers) {
            newState[index] = awards.slice(i, i + slideNumbers);
            index++;
        }
        setSlidesList(newState);
    };

    const nextGroup = () => {
        if (currentSlideNumber >= slidesList.length - 1) {
            setCurrentSlideNumber(0);
        } else {
            setCurrentSlideNumber(currentSlideNumber + 1);
        }
    };

    const prevGroup = () => {
        if (currentSlideNumber <= 0) {
            setCurrentSlideNumber(slidesList.length - 1);
        } else {
            setCurrentSlideNumber(currentSlideNumber - 1);
        }
    };

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    useEffect(() => {
        handleSlidesList(slideNumbers);
        window.addEventListener('resize', () => {
            handleSlidesList(getSlideNumbers());
            setCurrentSlideNumber(0);
        });
    }, []);

    useEffect(
        () => {
            resetTimeout();
            timeoutRef.current = setTimeout(() => {
                nextGroup();
            }, delay);

            return () => resetTimeout();
        },
        [currentSlideNumber]
    );

    return (
        <React.Fragment>
            {slidesList[currentSlideNumber] ? (
                <React.Fragment>
                    <div className="re-homepage-section-award-title">
                        <h1 className="re-marketing-section-title">INTERNATIONAL</h1>
                        <h1 className="re-hollow re-hollow-white re-marketing-pink re-marketing-section-subtitle">
                            RECOGNITION
                        </h1>
                    </div>
                    <div className="re-homepage-section-award-container">
                        <div
                            className="re-icon re-icon-lg re-icon-angle-left re-homepage-section-award-arrow"
                            data-testid="prevarrow"
                            onClick={prevGroup}
                        />

                        <div className="re-homepage-section-award-slides">
                            {slidesList[currentSlideNumber].map(award => (
                                <div
                                    className="re-homepage-section-award-item re-homepage-award-active"
                                    key={award.id}
                                    data-testid={`award-${award.id}`}
                                >
                                    <Image src={award.image} alt={award.alt} width={award.width} />
                                </div>
                            ))}
                        </div>

                        <div
                            className="re-icon re-icon-lg re-icon-angle-right re-homepage-section-award-arrow"
                            data-testid="nextarrow"
                            onClick={nextGroup}
                        />
                    </div>
                    <ul className="re-homepage-section-award-slidshow-container">
                        {[...slidesList.keys()].map(key => (
                            <li
                                key={key}
                                className={`re-homepage-section-award-slidshow ${
                                    key === currentSlideNumber ? 're-homepage-section-award-slidshow-active' : null
                                }`}
                            />
                        ))}
                    </ul>
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
};

export default AwardsContainer;
