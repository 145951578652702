import React from 'react';
import PropTypes from 'prop-types';

const Spinner = props => (
	props.loading ?
		<div className={`re-icon re-icon-spin text-center ${props.size} ${props.color ? props.color : ''}`} />
	: null)

Spinner.propTypes = {
	size: PropTypes.string.isRequired,
	color: PropTypes.string,
	loading: PropTypes.bool
};

export default Spinner;
