import request from './AxiosRequest';

const read = (data) =>
	request({
		url: `/Services/api/feed/homepage`,
		method: 'GET',
		cancel: true,
		data
	});


const Homepage = {
	read
};

export default Homepage;
