import React from 'react';
import ReactDOM from 'react-dom';
import CustomersReviewsContainer from './CustomersReviewsContainer';
import CustomersTestimonialContainer from './CustomersTestimonialContainer';
import NewsReleasesContainer from './NewsReleasesContainer';
import AwardsContainer from './AwardsContainer';

ReactDOM.render(
    <React.StrictMode>
        <CustomersTestimonialContainer />
    </React.StrictMode>,
    document.querySelector('#CustomerTestimonial')
);

ReactDOM.render(
    <React.StrictMode>
        <CustomersReviewsContainer />
    </React.StrictMode>,
    document.querySelector('#CustomerReview')
);

ReactDOM.render(
    <React.StrictMode>
        <NewsReleasesContainer />
    </React.StrictMode>,
    document.querySelector('#newsreleases')
);

ReactDOM.render(
    <React.StrictMode>
        <AwardsContainer />
    </React.StrictMode>,
    document.querySelector('#AwardsContainer')
);
