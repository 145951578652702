import React, { useState, useEffect, useRef } from 'react';

const CustomersTestimonialContainer = () => {
    const testimonials = [
        {
            id: 0,
            img: 'https://az291210.vo.msecnd.net/assets/bb2bb8b7-ae4c-4d33-9bb9-00749eaa5a07.png',
            desc: `The concept of RealTimeMLS, a method for our members to communicate newly signed listing agreements in
                        minutes with each other, is becoming a reality thanks to the power and reach that the RESAAS platform
                        provides.`,
            name: 'Walt Baczkowski',
            firm: `CEO of the San Francisco Association of REALTORS`
        },
        {
            id: 1,
            img: 'https://az291210.vo.msecnd.net/assets/bc03061b-d7a8-4d86-b1d4-cfba3ece4393.png',
            desc: `RealTimeMLS allows pre-market data to be captured in a single, trustworthy platform that utilizes listing data in an ethical manner. This partnership further paves the way for evolution of MLS technologies as well as the use of complete, accurate listing data in the industry.`,
            name: 'Aaron Famer',
            firm: `President of the Austin Board of REALTORS`
        },
        {
            id: 2,
            img: 'https://az291210.vo.msecnd.net/assets/8c5edc92-88c5-4ffb-b082-928978c8a999.jpeg',
            desc: `RealTimeMLS is an invaluable service, which provides our brokers and agents a solid strategic advantage and connects them to new opportunities.`,
            name: 'Amanda Creel',
            firm: `CEO of the Prescott Area Association of REALTORS`
        }
    ];
    const delay = 8000;
    const timeoutRef = useRef(null);

    const [id, setId] = useState(0);

    const nextTestimonial = () => {
        if (id === testimonials.length - 1) {
            setId(testimonials[0].id);
        } else {
            setId(id + 1);
        }
    };

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    useEffect(
        () => {
            resetTimeout();
            timeoutRef.current = setTimeout(() => {
                nextTestimonial();
            }, delay);

            return () => resetTimeout();
        },
        [id]
    );

    return (
        <div className="re-homepage-section-7-customers">
            <div className="re-homepage-section-7-customers-testimonial-container">
                {testimonials.map(testimonial => (
                    <div
                        key={testimonial.id}
                        data-testid={`testimonial-${testimonial.id}`}
                        className={
                            testimonial.id === id
                                ? 're-homepage-section-7-customers-testimonial-item re-homepage-customers-focus'
                                : 're-homepage-section-7-customers-testimonial-item'
                        }
                    >
                        <img className="re-homepage-section-7-customers-img" src={testimonial.img} />
                        <div className="re-homepage-section-7-customers-testimonial">
                            <div className="re-homepage-section-7-customers-desc">{testimonial.desc}</div>
                            <div className="re-homepage-section-7-customers-title">
                                <div className="re-homepage-section-7-customers-name">
                                    {testimonial.name}
                                </div>
                                <div>{testimonial.firm}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="re-next-testimonial-container">
                <div
                    className="re-icon re-icon-lg re-icon-angle-right re-next-testimonial"
                    data-testid="nextarrow"
                    onClick={nextTestimonial}
                />
            </div>
        </div>
    );
};

export default CustomersTestimonialContainer;
