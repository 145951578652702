import React, { useState, useEffect, useRef } from 'react';

const CustomersReviewsContainer = () => {
    const reviews = [
        {
            id: 0,
            desc: `RESAAS helps me to connect with fellow agents, which allows me to continue to grow my business.`
        },
        {
            id: 1,
            desc: `I like the ability to connect with so many industry professionals throughout the world.`
        },
        {
            id: 2,
            desc: `The Referral system is so great and easy to use. I've not found anything I don't like.`
        },
        {
            id: 3,
            desc: `Lead Generation and 90% chance to mature the deal of any property.`
        },
        {
            id: 4,
            desc: `I received an email stating I had a lead and next thing I know I am working with a strong buyer. Closed in less than 30 days!`
        }
    ];
    const delay = 6000;
    const timeoutRef = useRef(null);

    const [id, setId] = useState(0);

    const nextReview = () => {
        if (id === reviews.length - 1) {
            setId(reviews[0].id);
        } else {
            setId(id + 1);
        }
    };

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    useEffect(
        () => {
            resetTimeout();
            timeoutRef.current = setTimeout(() => {
                nextReview();
            }, delay);

            return () => resetTimeout();
        },
        [id]
    );

    return (
        <div className="re-homepage-section-7-customers-reviews">
            <div className="re-homepage-section-7-customers-reviews-stars">
                <span className="re-homepage-section-7-customers-reviews-stars-rate">4.4</span>
                <div>
                    <span className="re-icon re-icon-star re-icon-yellow re-icon-solid re-icon-lg" />
                    <span className="re-icon re-icon-star re-icon-yellow re-icon-solid re-icon-lg" />
                    <span className="re-icon re-icon-star re-icon-yellow re-icon-solid re-icon-lg" />
                    <span className="re-icon re-icon-star re-icon-yellow re-icon-solid re-icon-lg" />
                    <span className="re-icon re-icon-star-half re-icon-yellow re-icon-solid re-icon-lg" />
                </div>
            </div>
            <div className="re-homepage-section-7-customers-reviews-link">
                <a className="re-btn" href="https://www.capterra.com/p/231595/RESAAS/reviews/" target="_blank">
                    Rate us on Capterra
                </a>
            </div>
            <div className="re-homepage-section-7-customers-reviews-content">
                <div className="re-homepage-section-7-customers-reviews-content-stars">
                    <span className="re-icon re-icon-star re-icon-yellow re-icon-solid re-icon-lg" />
                    <span className="re-icon re-icon-star re-icon-yellow re-icon-solid re-icon-lg" />
                    <span className="re-icon re-icon-star re-icon-yellow re-icon-solid re-icon-lg" />
                    <span className="re-icon re-icon-star re-icon-yellow re-icon-solid re-icon-lg" />
                    <span className="re-icon re-icon-star re-icon-yellow re-icon-solid re-icon-lg" />
                </div>{' '}
                <div className="re-homepage-section-7-customers-reviews-items">
                    {reviews.map(review => (
                        <div
                            key={review.id}
                            data-testid={`review-${review.id}`}
                            className={
                                review.id === id
                                    ? 're-homepage-section-7-customers-reviews-item re-homepage-review-focus'
                                    : 're-homepage-section-7-customers-reviews-item'
                            }
                        >
                            <div className="re-homepage-section-7-customers-reviews-content-desc">{review.desc}</div>
                        </div>
                    ))}
                </div>
                <div
                    className="re-icon re-icon-lg re-icon-angle-right re-homepage-section-7-customers-reviews-next"
                    data-testid="nextarrow"
                    onClick={nextReview}
                />
            </div>
        </div>
    );
};

export default CustomersReviewsContainer;
